import * as React from "react"

import Card from "../components/Card"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

import work from "../data/pages/work.json" 

const WorkPage = () => {

  return (
    <>
      <Layout>
        <Seo title="Work" page="work" description="A curated collection of my partially finished and abandoned projects through the years."/>
        <div className="w-100 min-h-screen-fill-content into relative px-6 sm:px-0 py-16 lg:py-[20vh] 2xl:py-[10vh]">
          <div className="w-100 sm:w-3/4 xl:w-8/12 mx-auto">
            <div className="flex flex-col flex-1 top">
              {Object.keys(work).map((_, index) => {
                const project = work[`work-${index}`]
                return (
                  <Card 
                    title={project.title} 
                    desc={project.desc} 
                    links={project.links} 
                    tech={project.meta.tech}
                    i={index}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}
export default WorkPage
