import * as React from "react"

import "../styles/components/card.scss"
import { ICON_MAPPING, ICON_NAME_MAPPING } from "../utils"

const IconContainer = ({ children, id, index, length, style }) => {
  const title = ICON_NAME_MAPPING[id]
  return (
    <span 
      title={title} 
      style={style} 
      className={
        `text-primary-800 flex font-extralight ` +
        `text-lg xl:text-xl ` + 
        `${index !== 0 ? index === length - 1 ? `ml-3 lg:ml-5` : `mx-3 lg:mx-5` : `mr-3 lg:mr-5`}`
      } 
      key={`tech-${index}`}
    >
      {children}
    </span>
  )
}

const Technologies = ({ data }) => {

  return (
    <div className="my-4 lg:my-5 flex items-center justify-center text-xl flex-row technologies"> 
      {
        data.map((tech,index) => {
          const Icon = ICON_MAPPING[tech]
          const style = {transition:`opacity 0.5s ease ${index * 0.05}s`}
          return (
            <IconContainer index={index} length={data.length} id={tech} style={style} key={`tech-${index}`}>
              <Icon/>
            </IconContainer>
          )
        })
      }
    </div>
  )
}

const Title = ({ title }) => {
    return (
        <div 
            className={
                `text-pop text-center block font-emberly-black-narrow uppercase ` +
                `emb-lg xs:emb-xl title ` + 
                `leading-[2.5rem] xs:leading-[2.8rem] sm:leading-[3.25rem] md:leading-[3.5rem]`
            }
        >
            {title}
        </div>
    )
}

const Description = ({ text }) => {
    return (
    <div 
        className={
            `w-full xs:w-3/4 sm:w-4/5 mx-auto desc ` +
            `russolo-sm md:russolo-base font-black ` +
            `text-left sm:text-justify text-primary-800 `
        }
    >
        {text}
    </div>
    )
}

const Links = ({ links }) => {
  return (
    links.map((link, index) => {
      const style = {transition:`opacity 0.5s ease ${index * 0.05}s, color 0.3s ease, text-decoration-color 0.3s ease`}
      return (
        <div className="links flex flex-row items-center" key={`links-${index}`}>
          <a 
            className={
              `russolo-sm font-russolo font-black hover:text-pop text-primary-700 cursor-pointer ` +
              `decoration-primary-500 hover:decoration-pop hover:underline lowercase ` + 
              `transition-colors underline underline-offset-[0.5rem] ` +
              `${index > 0 ? `ml-2` : ``}`
            }
            style={style}
            target="_blank"
            href={link.url} rel="noreferrer"
          >
            {link.text}
          </a>
        </div>
      )
    })
  )
  
}

const Card = ({ title, desc, links, tech, i }) => {

    const cardRef = React.useRef(null)
    const [position,setPosition] = React.useState({
      enter: false,
      leaveRight: false, 
      leaveLeft: false
    }) 

    React.useEffect(() => {

      let previousY = 0
      let previousRatio = 0
  
      const oberserverHanlder = ([entry]) => {
  
        const currentY = entry.boundingClientRect.y
        const currentRatio = entry.intersectionRatio
        const isIntersecting = entry.isIntersecting

        let pos ; 
    
        if (currentY < previousY) {
          if (currentRatio > previousRatio && isIntersecting) {
            // "Scrolling down enter"
            pos = {
              enter: true, 
              leaveRight: false, 
              leaveLeft: false
            }
          } else {
            // Scrolling down leave
            pos = {
              enter: false, 
              leaveRight: true, 
              leaveLeft: false            
            }
          }
        } else if (currentY > previousY) {
          if (currentRatio < previousRatio) {
            if(isIntersecting) {
              //"Scrolling up enter"
              pos = {
                enter: true, 
                leaveRight: false, 
                leaveLeft: false
              }
            } else {
              // "Scrolling up leave"
              pos = {
                enter: false, 
                leaveRight: false, 
                leaveLeft: true
              }
            }
            
          } else {
            if (isIntersecting) {
              // Scrolling up enter
              pos = {
                enter: true, 
                leaveRight: false, 
                leaveLeft: false
              }
            }
            
          }
        }
        if(pos !== undefined) {
          setPosition({...pos})
        }
       
  
        previousY = currentY
        previousRatio = currentRatio
      }


      const observer = new IntersectionObserver(
        oberserverHanlder,
        {
          threshold: [0.3, 1],
          rootMargin: "30% 0% 0% 0%",
        }
      )

      if(cardRef.current && typeof(window) !== 'undefined') {
        window.setTimeout(() => observer.observe(cardRef.current),200)
      }
    },[]) 

    return (
      <div
        className={`flex card${i === 0 ? `` : ` mt-20`}${position.enter ? ` enter` : position.leaveRight ? ` leave-right` : position.leaveLeft ? ` leave-left` : ``}`}
        key={`work-${i}`}
        id={`work-${i}`}
        ref={cardRef}
      >
        <div className="flex flex-1 md:grow-[5] flex-col lg:grow">
          <div className="info flex flex-col relative">
            <Title title={title}/>
            <Technologies data={tech} position={position}/> 
            <Description text={desc}/>
          </div>
          <div className="flex mt-5 flex-row justify-center flex-wrap">
            <Links links={links} position={position}/>
          </div>
        </div>
      </div>
    )
}

export default Card ; 